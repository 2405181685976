.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#calendar {
  float: right;
  margin-top: 5%;
  max-width: 62%;
  min-width: 50%;
  background: white;
}

#calendar-for-user {
  float: right;
  margin-top: 8%;
  min-width: 50%;
  background: white;
  margin-right: 5%;
}

#wrapper-create-booking {
  max-width: 30%; 
  margin-bottom: 5%;
  float: left;
  margin-top: 3%;
}

.rbc-calendar {
  min-height: 580px;
  
}

.rbc-time-content, .rbc-time-header-gutter {
  display: none !important;
}

.rbc-time-header {
  height: auto;

}

.rbc-time-header-content {
  border-left: none;
}

.rbc-allday-cell {
  min-height: 580px ;
}

.PhoneInputInput {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border-radius: .25rem;
  border:1px solid #ced4da;
  height: calc(1.5em + .75rem + 2px);
}

.PhoneInputInput:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.react-date-picker__wrapper {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border-radius: .25rem;
  border:1px solid #ced4da !important;
  height: calc(1.5em + .75rem + 2px);
}

#dropdown-menu-align-right {
  background-color: white;
  color: black;
}

/* Rezerwacja wstępna */
#dropdown1 {
  background-color: #eaff61;
}

/* Rezerwacja potwierdzona */
#dropdown2 {
  background-color: #cbfff1;
}

/* Rezerwacja + zaliczka */
#dropdown3 {
  background-color: #61ff7b;
}

/* Rezerwacja rozliczona */
#dropdown4 {
  background-color: #31793d;
}

/* #editButton {
  float: left;
} */
#deleteButton {
  float: right;
}

#tdButtons {
  width: 15%;
}

#bookingTable {
  width: 100%;
}

#tdStartDate, #tdEndDate {
  width: 10%;
}

#goBackButton {
  float: left;
  margin-right: 1%;
}

#calendarButton {
  margin-bottom: 0.5%;
  margin-right: 1%;
}

#archiveButton {
  margin-bottom: 1%;
}

#searchError {
position: relative;
 width: 350px;
 left:50%;
 margin-left:-175px;
}

#footer-contact {
  text-align: left;
  float: left;
  width: 27%;
}

#footer-regulations {
  text-align: left;
  color: white;
}

.footer-regulations-a {
  color: white;
}

.footer-regulations-a:hover {
  text-decoration: none;
  color:gray;
}

#footer-localization {
  float: left;
  width: 35%;
  text-align: left;
}

#footer-center {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: .02143em;
  line-height: 1.25;
}

.row {
  text-align: center;
}

.container {
  margin-top: 15px;
  margin-bottom: 10%;
  background-color: white;
}

#contactWrapper {
  width: 100%;
  height: 100%;
  position: absolute; 
  top: 76px;
  left: 0;
  background-color: black;
  
}

#a-fb-contact {
  color: #333;
}

#logo {
  height: 60px;
}

.nav-item {
  margin-left: 40px;
}

.navbar-nav {
  width: 100%;
}

.nav-item a {
  color: rgb(255,255,255,0.5);
  text-decoration: none;
}

.nav-item a:hover{
  text-decoration: none;
  padding-bottom: 6px;
  color: white;
  border-bottom: 3px solid white;
}

.nav-item-admin {
  float: left;
  margin-left: 20px;
}

.nav-item-admin a {
  color: rgb(255,255,255,0.5);
  text-decoration: none;
}
.nav-item-admin a:hover {
  text-decoration: none;
  padding-bottom: 6px;
  color: white;
  border-bottom: 3px solid white;
}

#navbar-admin-logout {

  float: right;
}

#navbar-admin-links {
  margin-top: 1%;
  float: left;
  margin-left: 40%;
}

#navbar {
  z-index: 10000;
  position: fixed;
  top: 0px;
  width: 100%;
}

#map {
  position: relative;
  left:50%;
  padding-bottom: 8%;
}

#contactContent {
  position: relative;
  background-color: white;
  height: 100%;
  width: 100%;
  padding-top: 5%;
}

h2 {
  color: #343A40;
}

#contact-div-prev1 {
  position: relative;
  width: 100%;
  height: 30%;
  text-align: center;
}

#contact-img-prev1 {
  width: 100%;
  height: 100%;
}

#contactData {
  font-family: "Open Sans",sans-serif;
  position: relative;
  width: 40%;
  text-align: left;
  float: left;
  left: 20%;
  color: #333;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: .02143em;
  line-height: 1.25;
}

#h1-contact {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  height: 100px;
  margin-top: -50px;
  color: white;
  font-family: Georgia, serif;
  text-shadow: 2px 2px #272727;
  font-size: 75px;
}

#contact-img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -400px;
  margin-top: -80px;
}

p {
  color: #343A40;
}

#preview {
  width: 200px;
  height: 200px;
}

#containerBookingList {
  margin-top: 10%;
}

#logout-button {
  width: 60%;
  text-align: right;
}

#container-create-booking {
  width: 80%;
  /* height: 100%; */
  position: absolute; 
  top: 10%;
  left: 10%;
  /* overflow: hidden; */
}

#alert {
  position: relative;
  padding-top:20%;

}

#login-wrapper {
  margin-top: 10%;
}

#booking-wrapper {
  background: url("./bg-01.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

#booking-form {
  width: 20%;
  background: white;
  float: left;
  margin-left: 5%;
  margin-top: 7%;
  font-size: 15px;
}

#booking-bg {
  background: white;
  position: absolute;
  width: 70%;
  height: 88%;
  opacity: 0.95;
  left:15%;
  margin-top: 5%;
  border-radius: 25px;
  
}

.box-wrapper {
  background: url("./bg-01.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.wrapper-box-gallery {
  position: absolute;
  width: 70%;
  height: 88%;
  left:15%;
  margin-top: 2.5%;
  border-radius: 25px;
}

#price-list-table {
  width: 60%;
  margin-left: 20%;
  margin-top: 5%;

}

#price-list-table-extra {
  width: 70%;
  margin-left: 1%;
  margin-top: 5%;
  display: inline-flex;
}

.box-bg-extra {
  background: white;
  position: absolute;
  width: 90%;
  height: 88%;
  opacity: 0.95;
  left:5%;
  margin-top: 5%;
  border-radius: 25px;
  
}

#top-table {
  position: relative;
  left: 15%;
  width: 100%;
}

#bottom-table {
  position: relative;
  left:25%;
  width: 100%;

}

#price-list-content {
  color: #333;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: .02143em;
  line-height: 1.25;
  margin-left: 20%;
}

#price-list-p {
  font-size: 20px;

}

#cennik-img, #booking-regulations-img {
  width: 400px;
  margin-left: calc(50% - 200px);
  margin-top: 2%;
}

#regulations-img {
  width: 250px;
  margin-left: calc(50% - 125px);
  margin-top: 2%;
}

.box-bg {
  background: white;
  position: absolute;
  width: 70%;
  height: 88%;
  opacity: 0.95;
  left:15%;
  margin-top: 5%;
  border-radius: 25px;
  
}

#info {
  margin-top: 10%;
  text-align: justify;
  /* text-justify:kashida; */
  width: 75%;
  margin-left: 12.5%;
}

#info-h {
  text-align: center;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

#info-p {
  text-align: center;
  font-size: 12px;
  margin-top: 3%;
}

#price-list-extra {
  color: blue;
}

#info-accepted {
  text-align: center;
}

#p-calendar-info {
  font-size: 12px;
}

#update-modal {
  margin-top: 5%;
}

#main-page-container {
  margin-top: 7%;
}

#main-page-bg {
  background: url("./bg.jpg") no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  position: absolute;
  
}

#main-page-pic2 {
  height: 500px;
  width: 50%;
  float:left;
  margin-left: 0.8%;
  border: 2px solid #343A40;

}

#main-page-pic6 {
  height: 500px;
  width: 50%;
  margin-left: 17px;
  margin-right: 0.0%;
  border: 2px solid #343A40;

  
}

#main-page-pic3 {
  width: 50%;
  height: 500px;
  float:left;
  margin-top: 20px;
  margin-left: 0.8%;
  border: 2px solid #343A40;
  position: relative;
}

#equip-description {
  text-align: justify;
  width: 97.5%;
  position: relative;
  left: 20px;
  margin-top: 20px;
  height: 500px;
  color: #333;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: .02143em;
  line-height: 1.25;
}

.main-page-header {
  color: #333;
  font-size: 45px;
  font-weight: 300;
  letter-spacing: .02143em;
  line-height: 1.25;
  text-align: center;
}

#domki-description {
  text-align: justify;
  width: 94.5%;
  position: relative;
  left: 2.5%;
  margin-bottom: 20px;
  height: 500px;
  color: #333;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: .02143em;
  line-height: 1.25;

}

#plaza-description {
  text-align: justify;
  width: 47%;
  float:left;
  margin-left: 40px;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: .02143em;
  line-height: 1.25;
}

#carousel-container {
  background: url("./bg-01.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;

}



.background-box-gallery {
  margin-top: 5%;
  height: 88%;
  width: 70%;
  background: white;
  position: absolute;
  border-radius: 25px;
  left: 15%;
}

#gallery-img {
  width: 400px;
  margin-top: 2%;
  position: relative;
  left: calc(50% - 200px);
}

body {
  overflow-x: hidden;
}


.footer {
  width: 100%;
  height: 400px;
  margin-top: 1%;
  padding-top: 3%;
  background-color: #343A40;
  text-align: center;
  color: white;
}

#footer-title {
  color: rgb(179, 179, 179)
}

#footer-center {
  width: 100%;
  margin-left: 10%;
}

.footer-position {
  position: relative;
  top: 98%;
}

#copyright {
  float: left;
  width: 100%;
  margin-top: 50px;
}
#a-contact {
  color:white;
}

#a-contact:hover {
  text-decoration: none;
  color:gray;
}

#a-fb {
  color:white;

}

#a-fb:hover {
  text-decoration: none;
  color:gray;
}

#domki-desc-list {
  text-align: left;
  position: relative;
  left: 20%;
  list-style-position:inside;
}

#regulations-content {
  text-align: justify;
  width: 90%;
  margin-left: 5%;
}

#house-regulations-content {
  text-align: justify;
  width: 90%;
  margin-left: 5%;
  overflow-y: auto;
  height: 700px;
  padding-right: 10px;

}

@media screen and (min-width: 769px) and (max-width: 1366px) {

  .main-page-header {
    font-size: 30px 
  }

  #domki-description, #plaza-description, #equip-description {
    font-size: 16px;
  }

  #main-page-pic2, #main-page-pic6, #main-page-pic3 {
    width: 658px;
  }

  #booking-wrapper {
    height: 170%;
  }
  #booking-bg {
    height: 80%;
    margin-top: 6%;
  }

  .background-box-gallery {
    margin-top: 6.5%;
    height: 530px;
  }

  .wrapper-box-gallery {
    margin-top: 0;
    width: 60%;
    left: 20%;
  }

  #gallery-img {
    margin-top: 0;
  }

  #carousel-container {

    height:102%;
  
  }

  .box-bg {
 
    margin-top: 6.5%;
    height: 530px;
    
  }

  .box-wrapper {
    height: 102%;
  }

  .footer {
    height: 350px;
  }

  #cennik-img {
    width: 300px;
    margin-left: calc(50% - 150px);
  }

  #price-list-table {
    width: 60%;
    margin-left: 20%;
    margin-top: 1%;
  }

  #price-list-content {
    width: 60%;
  }

  #booking-regulations-img {
    width: 250px;
    margin-left: calc(50% - 125px)
  }

  #regulations-content {
    font-size: 0.74rem;
  }

  
  
}

@media screen and (max-width: 768px) {

  
  body {
    max-width: 100vw;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  #container, #root {
    overflow-x: hidden;
  }

  #navbar {
    font-size: 10px;
    height: 75px;
    max-width: 100vw;
  }

  #logo {
    position: absolute;
    top: 5px;
    left: calc(50% - 40px);
    height: 25px;
  }

  .navbar-nav {
    margin-top: 5%;
  }
  
  ul.navbar-nav {
    display: table;
    position: absolute;
    left: 0;
  }
  ul.navbar-nav > li {
    display: table-cell;
    padding: 10px;
  }

  #main-page-pic2, #main-page-pic6, #main-page-pic3 {
    min-width: 100vw;
    max-height: 60vw;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    float:none;
  }

  #domki-description, #plaza-description, #equip-description {
    font-size: 18px;
    height: fit-content;
    text-align:justify;
  }

  #plaza-description, #equip-description {
    position: relative;
    width: 94.5%;
    left: 2.5%;
    float: none;
    margin: 0;
  }

  .main-page-header {
    font-size: 30px;
    font-weight: bolder;
  }

  .footer {
    margin-top: 20px;
    height: 750px;
  }
  #footer-center {
    width: 100vw;
  }

  #footer-contact, #footer-regulations {
    width: 85%;
    float: none;
    text-align: justify;
  }

  #footer-contact h5, #footer-regulations{
    text-align: center;
  }

  #footer-regulations {
    padding-top: 5%;
  }

  #footer-localization {
    float: none;
    margin-top: 5%;
    width: 100%;
    text-align: center;
  }

  #footer-localization h5 {
    margin-right: 15%;
  }

  #footer-map {
    margin-left: 5%;
  }

  
  #booking-form {
    width: 80%;
    background: white;
    float: none;
    margin-left: 10%;
    font-size: 15px;
  }
  
  
  #booking-bg {
    position: fixed;
    overflow-y: scroll;
    width: 100vw;
    height: 1000px;
    left:0;
    margin-top: 5%;
    border-radius: 0;

  }

  .form-group {
    margin-bottom: 15px !important;
  }

  #calendar-for-user {
    position: relative;
    float:none;
    margin-top: 10%;
    min-width: 50%;
    background: white;
    margin-right: 5%;
    width: 100vw;
    padding-left: 1%;
    padding-right: 1%;
    
  }

  #booking-footer-position{
    visibility:hidden;
    position: unset;
  }

  #booking-bg {
    overflow-x: hidden;
  }

  
  #booking-wrapper {
   background: url("./bg-01.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
   position: absolute;
   top:0;
    left:0;
    width:100%;
    height:2000px;
  }

  .background-box-gallery {
    margin-top: 10%;
    height: 100%;
    width: 100vw;
    background: white;
    position: absolute;
    border-radius: 0;
    left: 0;
  }

  .wrapper-box-gallery {
    position: absolute;
    width: 100vw;
    height: 88%;
    left:0;
    margin-top: 2.5%;
    border-radius: 25px;
  }

  .box-bg {
    background: white;
    position: absolute;
    width: 100vw;
    height: auto;
    opacity: 0.95;
    left:0;
    margin-top: 10%;
    border-radius: 0;
    
  }

  .box-bg-extra {
    background: white;
    position: absolute;
    width: 100vw;
    height: auto;
    opacity: 0.95;
    left:0;
    margin-top: 10%;
    border-radius: 0;
    
  }

  .box-wrapper {
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  #price-list-table {
    width: 91%;
    margin-left: 4.5%;
    margin-top: 5%;
  }

  #price-list-content {
    text-align: justify;
    margin: 10%;
  }

  .footer-position {
    visibility: hidden;
    top: 0;
  }

  .footer-position-mobile {
    visibility:visible !important;
  }

  #contactData {
    font-family: "Open Sans",sans-serif;
    position: relative;
    width: 100vw;
    text-align: left;
    float: none;
    left: 10%;
    font-size: 20px;
  }

  .contact-icons {
    width: 20px;
    height: 20px;
  }

  #contactData h2 {
    font-size: 30px;
  }

  
#contact-img {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -40px;
}


#contactWrapper {
  width: 100%;
  height: 100%;
  position: absolute; 
  top: 75px;
  left: 0;
  background-color: black;
  
}

#contact-img-prev1{
  width: 100%;
  min-height: 80px;
}

#map {
  position: relative;
  left:0%;
  margin-top: 10%;
  width: 250%;
  padding-bottom: 8%;
}

#cennik-img {
  margin-top: 8%;
}


}

@media screen and (max-width: 500px) {

  body {
    max-width: 100vw;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  #container, #root {
    overflow-x: hidden;
  }

  #navbar {
    font-size: 10px;
    height: 75px;
    max-width: 100vw;
  }

  #logo {
    position: absolute;
    top: 5px;
    left: calc(50% - 40px);
    height: 25px;
  }

  .navbar-nav {
    margin-top: 5%;
  }
  
  ul.navbar-nav {
    display: table;
    position: absolute;
    left: 0;
  }
  ul.navbar-nav > li {
    display: table-cell;
    padding: 10px;
  }

  #main-page-pic2, #main-page-pic6, #main-page-pic3 {
    min-width: 100vw;
    max-height: 60vw;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    float:none;
  }

  #domki-description, #plaza-description, #equip-description {
    font-size: 18px;
    height: fit-content;
    text-align:justify;
  }


  #plaza-description, #equip-description {
    position: relative;
    width: 94.5%;
    left: 2.5%;
    float: none;
    margin: 0;
  }

  .main-page-header {
    font-size: 30px;
    font-weight: bolder;
  }

  .footer {
    margin-top: 20px;
    height: 650px;
  }
  #footer-center {
    width: 100vw;
  }

  #footer-contact, #footer-regulations {
    width: 85%;
    float: none;
    text-align: justify;
  }

  #footer-contact h5, #footer-regulations{
    text-align: center;
  }

  #footer-regulations {
    padding-top: 5%;
  }

  #footer-localization {
    float: none;
    margin-top: 5%;
    width: 100%;
    text-align: center;
  }

  #footer-localization h5 {
    margin-right: 15%;
  }

  #footer-map {
    margin-left: 5%;
  }

  
  #booking-form {
    width: 80%;
    background: white;
    float: none;
    margin-left: 10%;
    font-size: 15px;
  }
  
  
  #booking-bg {
    position: fixed;
    overflow-y: scroll;
    width: 100vw;
    height: 100%;
    left:0;
    margin-top: 5%;
    border-radius: 0;

  }

  .form-group {
    margin-bottom: 1px !important;
  }

  #calendar-for-user {
    position: relative;
    float:none;
    margin-top: 10%;
    min-width: 50%;
    background: white;
    margin-right: 5%;
    width: 100vw;
    padding-left: 1%;
    padding-right: 1%;
    
  }

  #booking-footer-position{
    visibility:hidden;
    position: unset;
  }

  #booking-bg {
    overflow-x: hidden;
  }

  
  #booking-wrapper {
   background: url("./bg-01.png") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
   position: absolute;
   top:0;
    left:0;
    width:100%;
    height:1000px;
  }

  .background-box-gallery {
    margin-top: 20%;
    height: 100%;
    width: 100vw;
    background: white;
    position: absolute;
    border-radius: 0;
    left: 0;
  }

  .wrapper-box-gallery {
    position: absolute;
    width: 100vw;
    height: 88%;
    left:0;
    margin-top: 2.5%;
    border-radius: 25px;
  }

  .box-bg {
    background: white;
    position: absolute;
    width: 100vw;
    height: auto;
    opacity: 0.95;
    left:0;
    margin-top: 20%;
    border-radius: 0;
    
  }

  .box-bg-extra {
    background: white;
    position: absolute;
    width: 100vw;
    height: auto;
    opacity: 0.95;
    left:0;
    margin-top: 20%;
    border-radius: 0;
    
  }

  #top-table {
    position: absolute;
    left: 10%;
    width: 80%;
  }
  
  #bottom-table {
    margin-top: 150%;
    left: 20%;
  }

  .box-wrapper {
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  #price-list-table {
    width: 91%;
    margin-left: 4.5%;
    margin-top: 5%;
  }

  #price-list-content {
    text-align: justify;
    margin: 10%;
  }

  .footer-position {
    visibility: hidden;
    top: 0;
  }

  .footer-position-mobile {
    visibility:visible !important;
  }

  #contactData {
    font-family: "Open Sans",sans-serif;
    position: relative;
    width: 100vw;
    text-align: left;
    float: none;
    left: 10%;
    font-size: 16px;
  }

  .contact-icons {
    width: 16px;
    height: 16px;
  }

  #contactData h2 {
    font-size: 25px;
  }

  
  #contact-img {
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -20px;
  }


  #contactWrapper {
    width: 100%;
    height: 100%;
    position: absolute; 
    top: 75px;
    left: 0;
    background-color: black;
    
  }

  #contact-img-prev1{
    width: 100%;
    min-height: 80px;
  }

  #map {
    position: relative;
    left:0%;
    margin-top: 10%;
    width: 250%;
    padding-bottom: 8%;
  }

  #booking-regulations-img {
    width: 250px;
    margin-left: calc(50% - 125px);
    margin-top: 2%;

  }

  #regulations-content {
    text-align: justify;
    margin-left: 2.5%;
  }
}

/* Ensure consistent image dimensions */
.custom-gallery .image-gallery-image {
  object-fit: cover; /* Ensures the image fills the container while preserving aspect ratio */
  width: 100%;       /* Adjust width to the container */
  height: 500px;     /* Set a fixed height */
}

.carousel-container {
  max-width: 1200px; /* Optional: Limit the width of the carousel */
  margin: 0 auto;   /* Center the carousel */
}

.image-gallery-thumbnails-container {
  padding: 10px 0;
  max-height: 120px;
  justify-content: center;
  gap: 10px; /* Space between thumbnails */
}

